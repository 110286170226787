export default [
  {
    header: 'My bookings',
  },
  {
    icon: 'ListIcon',
    title: 'Bookings list',
    route: 'foravila-home',
  },
]
