export const title = (value, replacer = " ") => {
  if (!value) return "";
  const str = value.toString();

  const arr = str.split(replacer);
  const capitalizedArray = [];
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });
  return capitalizedArray.join(" ");
};
